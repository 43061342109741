<script>
import paletteData from "@/data/colorPalette.json"
import PageTitle from "@/components/standardComponents/PageTitle.vue";
export default {
  name: 'SupportPage',
  components: {PageTitle},
  data(){
    return{
      style: paletteData.style
    }
  }
}
</script>

<template>
  <PageTitle :page-title="'ABOUT'" :page-subtitle="[]"/>
  <div class="about-screen">
    <div class="aboutMe">
      <div class="aboutContentHolder">
        <p class="aboutText">
          I created Studio Idem as a platform to share my creations with the world. The projects that I do are in the scope of game- and web design. Every project has its own purpose. Game projects are mostly for fun, while the web projects are tools that I think are useful for myself and other designers.
        </p>
        <br>
        <p class="aboutText" :style="{margin: '.5rem .5rem 2rem .5rem'}">
          The design and implementation are all done by myself since I like the variety of designing and programming. I'm always open for freelance projects or just fun projects to work on for my own portfolio. If you have any suggestions, send me a dm on Twitter.
        </p>
        <a class="icon" href='https://twitter.com/studio_idem' target='_blank'>
          <img src='@/assets/SocialMedia/TwitterLogo.png' alt="X/twitter logo" class="socialMediaIcon">
        </a>
      </div>
      <div class="aboutPerson">
        <img src='@/assets/profilePic.jpeg' alt="profile picture" class="aboutPhoto">
        <div>
          <h3 class="about-person-title">Lars Hak</h3>
          <p class="about-person-paragraph">Owner of Studio Idem</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.icon{
  padding: 1rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: #db6457;
}
.about-screen{
  width: calc(100vw - 6rem);
  display: flex;
  justify-content: center;
  background-color: #f7f2ea;
  background-image: url(http://www.transparenttextures.com/patterns/subtle-grunge.png);
  background-repeat: repeat;
  border-radius: 0 0 1vw 1vw;
  padding: 3rem 3rem 2rem 3rem;
  z-index: 3;
}
.aboutMe{
  width: 70%;
  border-radius: 1vw;
  display: grid;
  grid-template-columns: 60% 35%;
  gap: 1vw;
  padding: 1vw;
}
.aboutContentHolder{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 1.5vw;
}
.aboutText{
  margin: 1rem .5rem 0 .5rem;
  text-align: start;
  font-size: 1.2rem;
  width: 90%;
}
.aboutPhoto{
  width: 16vw;
  height: 16vw;
  margin: 2.75vw 0 0 1.5vw;
  object-fit: cover;
  object-position: 0 10%;
}
.aboutPerson{
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 2.25vw;
  text-align: left;
}
.about-person-title{
  margin: .5rem 0 0 .75rem;
  font-size: 1.5rem;
}
.about-person-paragraph{
  margin: .2rem 0 0 .75rem;
  font-size: 1rem;
}


@media (max-width: 950px){
  .aboutMe{
    width: 90%;
    grid-template-columns: 100%;
  }
  .aboutPhoto {
    width: 20rem;
    height: 20rem;
  }
  .aboutPerson{
    width: 20rem;
    height: 26rem;
    justify-self: center;
  }
}

@media (max-width: 600px) {
  .aboutMe{
    width: 100%;
  }
  .aboutPerson{
    width: 80%;
    height: 70%;
    justify-self: center;
  }
  .aboutPhoto {
    width: 100%;
    height: 80%;
  }
}
</style>