<script>
export default {
  props: {
    pageTitle: String,
    pageSubtitle: Array
  },
  computed: {
    repeatedSubtitles() {
      let repeatedArray = [];
      let index = 0;

      // Generate enough subtitles to create overflow
      while (repeatedArray.length < 35) {
        repeatedArray.push(this.pageSubtitle[index % this.pageSubtitle.length]);
        index++;
      }

      return repeatedArray;
    }
  }
}
</script>

<template>
  <div class="page-top">
    <h1 class="page-title">{{ pageTitle }}</h1>
    <div class="page-subtitle-holder">
      <h2 v-for="(subtitle, index) in repeatedSubtitles"
          :key="index"
          class="page-subtitle">
        {{ subtitle }}
      </h2>
    </div>
  </div>
</template>

<style>
.page-top{
  height: 25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.page-title{
  font-size: 16vw;
  letter-spacing: -.8vw;
  margin: 0;
  z-index: 1;
}

.page-subtitle-holder{
  position: fixed;
  display: flex;
  width: 140%;
  flex-wrap: wrap;
  justify-content: center;
  height: 25vw;
  margin-bottom: 6.5vw;
}

.page-subtitle{
  font-size: 5vw;
  margin: -.3vw .75vw;
  color: #e28b82;
  opacity: .2;
  height: 5vw;
}
</style>