<script>
import NavBar from "@/components/standardComponents/NavBar.vue";
import BottomBar from "@/components/standardComponents/BottomBar.vue";

export default {
  name: "NormalPage",
  components: {BottomBar, NavBar}
}
</script>

<template>
  <div class="wholeSite">
    <NavBar/>
    <router-view class="contents"/>
    <BottomBar/>
  </div>
</template>

<style scoped>
</style>