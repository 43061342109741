<script>
export default {
  props: ['projectData'],
  methods: {
    isInternalLink(url) {
      return url.startsWith('/') || url.startsWith(window.location.origin);
    }
  }
}
</script>

<template>
  <div class="project-screen">
    <component
        v-for="(projects, index) in projectData"
        :key="projects.title"
        :is="isInternalLink(projects.url) ? 'router-link' : 'a'"
        :to="isInternalLink(projects.url) ? projects.url : undefined"
        :href="!isInternalLink(projects.url) ? projects.url : undefined"
        class="show-project-block"
        :target="!isInternalLink(projects.url) ? '_blank' : undefined"
        @mouseover="projects.showData = true"
        @mouseleave="projects.showData = false"
        :class="{'reverse-order': index % 2 !== 0}"
    >
      <img class="project-image" :src="projects.image" :alt="'profile picture for' + projects.title" />
      <div class="project-text-below">
        <h2 class="project-title">{{ projects.title }}</h2>
        <div class="tag-container">
          <span v-for="tag in projects.tags" :key="tag" class="tag">{{ tag }}</span>
        </div>
        <p class="project-description" v-html="projects.text"></p>
      </div>
    </component>
  </div>
</template>


<style>
.project-screen {
  width: calc(100% - 20vw);
  padding: 5rem 10vw 4rem 10vw;
  font-family: "Oswald Bold", sans-serif;
  color: #db6457;
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: #f7f2ea;
  background-image: url(http://www.transparenttextures.com/patterns/subtle-grunge.png);
  background-repeat: repeat;
  gap: 5rem;
  z-index: 3;
}

.show-project-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
}


.reverse-order {
  flex-direction: row-reverse;
}

.project-image {
  width: 33%;
  aspect-ratio: 1 / 1;
  border-radius: 1vw;
  object-fit: cover;
  align-self: center;
}


.project-text-below {
  width: 67%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  text-align: left;
  padding: 0 1rem;
}

.project-title {
  margin: 0 0 .25rem 1rem;
  font-size: 2.5rem;
  color: #db6457;
}

.tag-container {
  display: flex;
  flex-wrap: wrap; /* Allow tags to wrap onto new lines */
  gap: .5rem;
  margin: .5rem 0 2rem 1rem;
}

.tag {
  background-color: #db6457;
  color: white;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  font-size: .9rem;
  font-family: "Oswald Bold", sans-serif;
}


.project-description {
  width: 90%;
  margin: 0 0 0 1.3rem;
  font-size: 1.2rem;
  font-family: "Crimson Text", serif;
  color: #db6457;
}

@media (max-width: 1000px) {
  .project-screen {
    width: calc(100vw - 30vw);
    padding: 5rem 15vw 4rem 15vw;
    gap: 5rem;
  }

  .show-project-block {
    flex-direction: column;
  }

  .project-image {
    width: 100%;
    aspect-ratio: auto;
  }

  .project-text-below {
    width: 100%;
  }

  .project-text-below {
    order: 2;
  }

  .project-image {
    order: 1;
  }
}

@media (max-width: 600px) {
  .project-screen {
    width: calc(100vw - 20vw);
    padding: 5rem 10vw 4rem 10vw;
    gap: 3rem;
  }

  .show-project-block {
    flex-direction: column;
  }

  .project-image {
    width: 100%;
  }

  .project-text-below {
    width: 100%;
  }

  .project-text-below {
    order: 2;
  }

  .project-image {
    order: 1;
  }
}

</style>
