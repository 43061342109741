
<script>
import paletteData from "@/data/colorPalette.json"
import projectDataJS from "@/data/projectData.json";
import ProjectShow from "@/components/standardComponents/ProjectShow.vue";
import PageTitle from "@/components/standardComponents/PageTitle.vue";
export default {
  name: 'SupportPage',
  components: {PageTitle, ProjectShow},
  data(){
    return{
      projectDataJSON: projectDataJS,
      palette: paletteData.palette
    }
  }
}
</script>

<template>
  <PageTitle :page-title="'STUDIO IDEM'" :page-subtitle="['Creative', 'Software', 'Development']"/>
  <div class="home-page-titles">
    <h3 class="home-page-small-title">What I have build to this point</h3>
    <h1 class="home-page-big-title">WEB PROJECTS</h1>
  </div>
  <ProjectShow :project-data="projectDataJSON.webProjects" class="home-page-projects"/>
  <div class="home-page-titles">
  <h1 class="home-page-big-title">GAME PROJECTS</h1>
</div>
  <ProjectShow :project-data="projectDataJSON.gameProjects" class="home-page-projects"/>
</template>

<style>
.home-page-titles{
  padding: 5rem 10vw 0 10vw;
  text-align: start;
  width: calc(100% - 20vw);
  z-index: 3;
  background-color: #f7f2ea;
  background-image: url(http://www.transparenttextures.com/patterns/subtle-grunge.png);
}
.home-page-small-title{
  margin: 0 1rem;
  font-size: 1.3rem;
  color: #8e5d4e;
}

.home-page-big-title{
  font-size: 4rem;
  margin: 1rem;
}

.home-page-projects{
  z-index: 2;
}
</style>