<script setup>

</script>

<template>
  <h1 class="pageTitle">404: Not Found</h1>
  <div class="standard-white-back">
  </div>
</template>

<style>
.standard-white-back{
  height: 40vw;
  background-color: #f7f2ea;
  width: 95%;
  border-radius: 0 0 1vw 1vw;
  font-family: "Oswald Bold", serif;
}
</style>