<script>
import axios from 'axios';
import Login from './components/AuthLogin.vue';
import Player from './components/MusicPlayer.vue';

export default {
  data() {
    return {
      authenticated: false,
      accessToken: '',
      refreshToken: '',
      playerComponent: null,
      playerProps: {},
      tokenRefreshInterval: null,
      base_url: 'http://146.190.238.121',
      rfidData: null,
    };
  },
  components: {
    Login,
    Player,
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.accessToken = urlParams.get('access_token');
    this.refreshToken = urlParams.get('refresh_token');

    if (this.accessToken) {
      localStorage.setItem('spotify_access_token', this.accessToken);
      localStorage.setItem('spotify_refresh_token', this.refreshToken);
      this.authenticated = true;
    } else {
      this.accessToken = localStorage.getItem('spotify_access_token');
      this.refreshToken = localStorage.getItem('spotify_refresh_token');
      this.authenticated = false;
    }

    if (this.authenticated) {
      await this.loadPlayer();
      this.setupTokenRefresh();
    }
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    clearInterval(this.tokenRefreshInterval);
  },
  methods: {
    async loadPlayer() {
      try {
        this.playerComponent = Player;
        this.playerProps = {
          accessToken: this.accessToken,
          refreshToken: this.refreshToken,
          refreshAccessToken: this.refreshAccessToken,
        };
      } catch (error) {
        console.error('Error loading Player component:', error);
      }
    },
    async refreshAccessToken() {
      try {
        const response = await axios.post(`${this.base_url}/auth/refresh_token`, {
          refresh_token: this.refreshToken,
        });
        this.accessToken = response.data.access_token;
        localStorage.setItem('spotify_access_token', this.accessToken);
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    }, setupTokenRefresh() {
      // Clear any existing interval
      if (this.tokenRefreshInterval) {
        clearInterval(this.tokenRefreshInterval);
      }

      // Set up a new interval
      this.tokenRefreshInterval = setInterval(async () => {
        if (this.isTokenExpired()) {
          await this.refreshAccessToken();
        }
      }, 3600 * 1000); // Refresh token every hour
    },
    isTokenExpired() {
      // Implement your logic to check if the token is expired
      // For example, compare the expiration time with the current time
      // Return true if expired, false otherwise
      // Sample logic:
      const expirationTime = localStorage.getItem('spotify_token_expiration');
      return expirationTime && new Date(expirationTime) < new Date();
    },
  },
};
</script>

<template>
  <div class="topHitApp">
    <Login v-if="!authenticated" />
    <template v-else>
      <Player
          v-if="playerComponent"
          :accessToken="playerProps.accessToken"
          :refreshToken="playerProps.refreshToken"
          :refreshAccessToken="playerProps.refreshAccessToken"
          ref="player"
      />
    </template>
    <div v-if="authenticated && !playerComponent">Player component not loaded</div>
  </div>
</template>

<style>
.topHitApp{
  width: calc(100% - 2rem);
  background-color: #f7f2ea;
  background-image: url(http://www.transparenttextures.com/patterns/subtle-grunge.png);
  background-repeat: repeat;
  padding: 2rem 0;
}
</style>
