import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '@/components/standardComponents/NotFound.vue';
import HomePage from "@/components/mainPages/HomePage.vue";
import StyleMaker from '@/components/webTools/styleMaker/StyleMaker.vue';
import AboutPage from "@/components/mainPages/AboutPage.vue";
import TermsOfService from "@/components/legal/TermsOfService.vue";
import PrivacyPolicy from "@/components/legal/PrivacyPolicy.vue";

import SupportPage from "@/components/mainPages/SupportPage.vue";
import HitApp from "@/components/webTools/top2000HitGame/HitApp.vue";
/*import standalonePage from "@/StandalonePage.vue";*/

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/web/stylemaker',
        name: 'StyleMaker',
        component: StyleMaker
    },
    {
        path: '/web/hitapp',
        name: 'HitApp',
        component: HitApp
    },{
        path: '/company',
        name: 'About',
        component: AboutPage
    },{
        path: '/company/terms-of-service',
        name: 'TermsOfService',
        component: TermsOfService
    },{
        path: '/company/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
    },{
        path: '/support',
        name: 'SupportPage',
        component: SupportPage
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router