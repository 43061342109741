<template>
  <div class="mainScreen">
    <div class="leftArea">
      <div class="showArea">
        <ExampleArea :color-palette-full="colorPaletteFull" :chosen-fonts="chosenFonts" :input-text="inputText" :input-size="inputSize"/>
      </div>
    </div>
    <div class="changeStyleArea">
      <div class="stylePicker">
        <DetailArea ref="detailsPage" :color-palette="colorPaletteFull" :chosen-fonts="chosenFonts"/>
        <div class="colorAndButton">
          <ColorPicker :send_color_to_parent="getChosenColor"/>
          <div class="exportButton">
            <button @click="exportStyle">Export Stylesheet</button>
          </div>
        </div>
        <GetFonts  :send_font_to_parent="getChosenFont"/>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from './ColorPicker.vue'
import GetFonts from './GetFonts.vue'
import ExampleArea from './ExampleArea.vue'
import DetailArea from './DetailArea.vue'

export default {
  name: 'StyleMaker',
  components: {
    ColorPicker,
    GetFonts,
    ExampleArea,
    DetailArea,
  },
  data() {
    return {
      chosenFonts: [],
      chosenColor: `#ffffff`,
      colorPaletteFull: [{'color': '#aebed9', 'type': ''},{'color': '#82a2d9', 'type': ''},{'color': '#8290d9', 'type': ''},{'color': '#8582d9', 'type': ''},{'color': '#1f1f33', 'type': ''}],
      colorPalette: [],
      inputText: [],
      inputSize: []
    }
  },
  methods: {
    getChosenFont(fontsChosen, inputText, inputSize) {
      this.chosenFonts = fontsChosen
      this.inputText = inputText
      this.inputSize = inputSize
    },
    getChosenColor(colorChosen, colorPalette) {
      this.chosenColor = colorChosen
      this.colorPaletteFull = colorPalette
      this.setPaletteToColorOnly()
    },
    setPaletteToColorOnly() {
      this.colorPalette = this.colorPaletteFull.map(item => item.color)
    },
    exportStyle() {
      let w = window.open()
      w.document.write(this.$refs.detailsPage.$el.innerHTML)
      w.document.close()
      w.setTimeout(function () {
        w.print()
      }, 1000)},
  }
}
</script>

<style>
.mainScreen{
  width: 100%;
  color: #db6457;
  display: flex;
  height: 42.2vw;
  padding: 2.5vw 0 1vw 0;
  background-color: #f7f2ea;
  border-radius: 0 0 1vw 1vw;
  background-image: url(http://www.transparenttextures.com/patterns/subtle-grunge.png);
}
.leftArea{
  width: 35%;
}
.stylePicker{
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 40vw;
}
.changeStyleArea{
  width: 59.5%;
}
.showArea{
  width: 87%;
  height: 38.75vw;
  background-color: white;
  border-radius: 1vw;
  margin: 0 1vw 1vw 4.5vw;
}
.exportButton{
  display: flex;
  margin: 0 0 0 1.5vw;
  width: 34.94%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 1vw;
}
button{
  background-color: v-bind(colorPalette[3]);
}
select:focus {
  background-color: v-bind(colorPalette[3]);
}
.colorAndButton{
  width: 54vw;
  display: flex;
  margin-top: 1.5vw;
}
</style>
